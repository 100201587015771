<template>
  <div class="max-h-98 rounded-lg w-auto flex justify-center items-center">
    <video
      v-if="isVideo"
      :src="src"
      title="Carousel video"
      controls
      class="min-h-59 rounded-lg w-full max-h-98 bg-black"
    />
    <img
      v-else
      :src="src"
      alt="Carousel image"
      class="max-h-96 rounded-lg object-contain"
      @load="emit('load', $event.target)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { isFileVideo } from '@/utils/file';

const emit = defineEmits<{
  load: [];
}>();

type Props = {
  src: string;
};

const props = defineProps<Props>();

const isVideo = computed(() => {
  return isFileVideo(props.src);
});
</script>
