import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "112",
  height: "112",
  fill: "currentColor",
  viewBox: "0 0 112 112"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m102.76 78.494-14.607-34.16C85.494 38.08 81.527 34.534 77 34.3c-4.48-.233-8.82 2.894-12.133 8.867L56 59.08c-1.867 3.36-4.526 5.367-7.42 5.6-2.94.28-5.88-1.26-8.26-4.293l-1.026-1.307c-3.314-4.153-7.42-6.16-11.62-5.74S19.88 56.654 17.5 61.367l-8.073 16.1c-2.894 5.833-2.614 12.6.793 18.107s9.333 8.82 15.82 8.82h59.547a18.61 18.61 0 0 0 15.54-8.354c3.546-5.226 4.106-11.806 1.633-17.546M32.527 39.107c8.71 0 15.773-7.062 15.773-15.773S41.238 7.56 32.527 7.56c-8.712 0-15.774 7.062-15.774 15.773s7.062 15.773 15.773 15.773" }, null, -1)
  ])))
}
export default { render: render }