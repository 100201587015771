<template>
  <div
    class="relative aspect-square w-full"
    :style="{ backgroundImage: `url(${activeFile})` }"
  >
    <div class="h-full w-full flex items-center justify-center backdrop-blur">
      <video
        v-if="isVideo"
        :src="activeFile.src"
        title="Carousel video"
        controls
        class="h-full w-full bg-black"
      />
      <template v-else>
        <img
          :src="activeFile.src"
          alt="Carousel image"
          class="object-contain w-full z-10"
        />
        <div
          class="absolute -top-5 -left-5 w-[110%] h-[110%] z-0 bg-[black]"
          :style="
            backgroundVariant === SliderBackgroundVariant.BLUR
              ? `background-image: url(${activeFile.src}); filter: blur(10px);`
              : ''
          "
        />
      </template>
    </div>
    <btn
      v-if="enableNext"
      :size="BtnSize.SM"
      :shape="BtnShape.ROUNDED"
      :color="BtnColor.SECONDARY"
      icon
      aria-label="View next image"
      class="absolute carousel-control right-2 bg-[#ffffff]/70"
      @click="next"
    >
      <icon :icon="ArrowRightSvg" />
    </btn>
    <btn
      v-if="enablePrevious"
      :size="BtnSize.SM"
      :shape="BtnShape.ROUNDED"
      :color="BtnColor.SECONDARY"
      icon
      aria-label="View previous image"
      class="absolute carousel-control left-2 bg-[#ffffff]/50"
      @click="previous"
    >
      <icon :icon="ArrowLeftSvg" />
    </btn>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import ArrowLeftSvg from '@/assets/icons/arrow-left.svg?component';
import ArrowRightSvg from '@/assets/icons/arrow-right.svg?component';
import { Btn, BtnColor, BtnShape, BtnSize } from '~/components/btn';
import { Icon } from '~/components/icon';
import { isFileVideo } from '@/utils/file';
import { SliderBackgroundVariant } from '~/components/carousel';

const emit = defineEmits<{
  'active-index-update': [index: number];
}>();

type Props = {
  files: { src: string; id: string }[];
  backgroundVariant: SliderBackgroundVariant;
};

const props = defineProps<Props>();

const activeIndex = ref(0);

const activeFile = computed(() => {
  return props.files[activeIndex.value];
});

const next = () => {
  activeIndex.value = Math.min(activeIndex.value + 1, props.files.length - 1);
};

const previous = () => {
  activeIndex.value = Math.max(activeIndex.value - 1, 0);
};

const enableNext = computed(() => {
  return activeIndex.value !== props.files.length - 1;
});

const enablePrevious = computed(() => {
  return activeIndex.value !== 0;
});

const isVideo = computed(() => {
  return isFileVideo(activeFile.value.src);
});

watch(activeIndex, () => emit('active-index-update', activeIndex.value));
</script>

<style scoped>
.carousel-control {
  top: calc(50% - 0.75rem);
}
</style>
