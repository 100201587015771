<template>
  <div class="h-full overflow-hidden grid grid-rows-6 grid-cols-6 gap-0.5">
    <div
      v-for="(file, i) in displayFiles"
      :key="file.id"
      :class="getClassNames(i)"
      class="overflow-hidden relative"
    >
      <video
        v-if="isFileVideo(file)"
        :src="file.src"
        title="Carousel video"
        class="h-full w-full bg-black"
        :controls="i === 0"
      />
      <img
        v-else
        :src="file.src"
        alt="Social Post Image"
        class="object-cover object-center w-full h-full"
      />
      <div
        v-if="remainingFiles > 0 && i === displayFiles.length - 1"
        class="absolute top-0 left-0 h-full w-full flex justify-center items-center bg-[#000000]/50 text-[white] text-3xl"
      >
        +{{ remainingFiles }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { isFileVideo } from '@/utils/file';

type Props = {
  files: { src: string; id: string }[];
};

const props = defineProps<Props>();

const displayFiles = computed(() => {
  return props.files.slice(0, 4);
});

const remainingFiles = computed(() => {
  return props.files.length - displayFiles.value.length;
});

const getClassNames = (i: number) => {
  switch (props.files.length) {
    case 1: {
      return i === 0 && 'row-span-6 colspan-6';
    }
    case 2: {
      return 'row-span-6 col-span-3';
    }
    case 3: {
      return i === 0 ? 'row-span-6 col-span-4' : 'col-span-2 row-span-3';
    }
    default: {
      return i === 0 ? 'col-span-6 row-span-4' : 'col-span-2 row-span-2';
    }
  }
};
</script>
