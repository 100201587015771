<template>
  <Placeholder v-if="files.length === 0" />
  <SingleFile
    v-else-if="files.length === 1"
    :src="files[0].src"
    @load="emit('load', $event)"
  />
  <div v-else class="aspect-square w-full rounded-lg overflow-hidden">
    <Grid v-if="variant === CarouselVariant.GRID" :files="files" />

    <Slider
      v-else
      :files="files"
      :background-variant="sliderBackgroundVariant"
      @active-index-update="emit('active-index-update', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { CarouselVariant, SliderBackgroundVariant } from './carousel.constants';
import { Placeholder, SingleFile, Slider, Grid } from './_components';

const emit = defineEmits<{
  load: [];
  'active-index-update': [index: number];
}>();

type Props = {
  variant: CarouselVariant;
  files: { src: string; id: number }[];
  sliderBackgroundVariant?: SliderBackgroundVariant;
};

withDefaults(defineProps<Props>(), {
  variant: CarouselVariant.GRID,
  sliderBackgroundVariant: SliderBackgroundVariant.BLUR,
});
</script>
